import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Modal, TextField, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { Job } from '../../DatabaseEntities/Job/Job';
import { CustomerBookingType } from '../../DatabaseEntities/CustomerBooking/CustomerBookingType';
import TimeslotSelector from '../CustomerPortal/TimeslotSelector';
import CreateCustomerModal from './CreateCustomerModal';
import { Customer } from '../../DatabaseEntities/User/Customer';
import { ManualJobCreationModel } from '../../Models/ManualJobCreationModel';
import { Staff } from '../../DatabaseEntities/User/Staff/Staff';
import LoadingComponent from '../Loading';

interface CreateJobModalProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    handleJobCreated: (job: Job) => void;
}

const CreateJobModal = ({ open, setOpen, handleJobCreated }: CreateJobModalProps) => {

    const [openCustomerModal, setOpenCustomerModal] = useState(false);

    // Jobs entities don't have staff Ids, as we use the Staff/Job mapping table, so we will use a model
    const [job, setJob] = useState<ManualJobCreationModel>(new ManualJobCreationModel());

    const [customers, setCustomers] = useState<Customer[] | null>(null);
    const [staff, setStaff] = useState<Staff[] | null>(null);
    const [selectedCustomer, setSelectedCustomer] = useState<Customer>(new Customer());

    useEffect(() => {
        setJob((prevJob) => ({
            ...prevJob,
            customerId: selectedCustomer.id,
        }));
    }, [selectedCustomer])

    useEffect(() => {
        if (open) {
            setCustomers(null);
            setStaff(null);
            const fetchCustomersAndStaff = async () => {
                try {
                    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/Customer`, {
                        method: 'GET',
                    });
                    if (!response.ok) {
                        throw new Error('Failed to fetch customers');
                    }
                    const data: Customer[] = await response.json();
                    setCustomers(data);
                } catch (error) {
                    console.error(error);
                }

                try {
                    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/Staff`, {
                        method: 'GET',
                    });
                    if (!response.ok) {
                        throw new Error('Failed to fetch staff');
                    }
                    const data: Staff[] = await response.json();
                    setStaff(data);
                } catch (error) {
                    console.error(error);
                }

            };

            fetchCustomersAndStaff();
        }
    }, [open]);

    // Handle input change for job form
    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setJob((prevJob) => ({
            ...prevJob,
            [name as string]: value,
        }));
    };

    const handleCustomerDropdownChange = (e: any) => {
        const { value } = e.target;

        var selected = customers?.find(a => a.id == value);

        if (selected) {
            setJob((prevJob) => ({
                ...prevJob,
                customerId: selected?.id as string,
            }));
        }
    };

    const handleStaffDropdownChange = (e: any) => {
        const { value } = e.target;

        var selected = staff?.find(a => a.id == value);

        if (selected) {
            setJob((prevJob) => ({
                ...prevJob,
                staffId: selected?.id as string,
            }));
        }
    };

    const handleSubmit = () => {
        if (!job.customerId || !job.bookingType || !job.estimatedFee || !job.estimatedHours || !job.timeslotUTC || !job.staffId) {
            return;
        }


        const saveJob = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/Job`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(job),
                });
                if (!response.ok) {
                    throw new Error('Failed to save job');
                }
                const data: Job = await response.json();
                handleJobCreated(data);
                setJob(new ManualJobCreationModel());
                setOpen(false);
            } catch (error) {
                console.error(error);
            }
        };
        saveJob();
    };

    const handleCustomerCreated = (customer: Customer) => {
        customers?.push(customer);
        setCustomers(customers);
        setSelectedCustomer(customer);
    }

    const timeslotSelectorRef = useRef<{ reset: () => void }>(null);

    return (
        <>
            <Modal open={open} onClose={() => setOpen(false)}>
                <Box sx={{
                    position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
                    backgroundColor: 'white', padding: 4, width: 400, boxShadow: 24, height: 800
                }}>
                    <h2>Create Job</h2>

                    <Button onClick={() => setOpenCustomerModal(true)}>Create customer</Button>

                    <FormControl fullWidth margin="normal" required>
                        <InputLabel>Customer</InputLabel>
                        <Select
                            value={job.customerId}
                            label="Customer"
                            name="customer"
                            onChange={handleCustomerDropdownChange}
                        >
                            {customers ?
                                customers.map(customer => (
                                    <MenuItem key={customer.id} value={customer.id}>
                                        {customer.firstName + " " + customer.lastName}
                                    </MenuItem>
                                )) : <LoadingComponent />}
                        </Select>
                    </FormControl>



                    <FormControl fullWidth margin="normal" required>
                        <InputLabel>Assign Staff member</InputLabel>
                        <Select
                            value={job.staffId}
                            label="Assign Staff member"
                            name="staff"
                            onChange={handleStaffDropdownChange}
                        >
                            {staff ?
                                staff.map(staffMember => (
                                    <MenuItem key={staffMember.id} value={staffMember.id}>
                                        {staffMember.firstName + " " + staffMember.lastName}
                                    </MenuItem>
                                )) :  <LoadingComponent/>}
                        </Select>
                    </FormControl>




                    <TextField
                        label="Estimated Hours"
                        type="number"
                        name="estimatedHours"
                        value={job.estimatedHours}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required
                    />

                    <TextField
                        label="Estimated Fee"
                        type="number"
                        name="estimatedFee"
                        value={job.estimatedFee}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required
                    />

                    <FormControl fullWidth margin="normal" required>
                        <InputLabel>Booking Type</InputLabel>
                        <Select
                            value={job.bookingType}
                            label="Booking Type"
                            name="bookingType"
                            onChange={handleChange}
                        >
                            {Object.keys(CustomerBookingType)
                                .filter(key => isNaN(Number(key))) // Filters out the numeric values
                                .map(key => (
                                    <MenuItem key={key} value={CustomerBookingType[key as keyof typeof CustomerBookingType]}>
                                        {key}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>

                    <TimeslotSelector ref={timeslotSelectorRef} onTimeslotSelect={(timeslotUTC: any) => setJob((prev) => ({ ...prev, timeslotUTC }))} />

                    <Button onClick={handleSubmit} fullWidth variant="contained">Submit</Button>
                </Box>
            </Modal>
            <CreateCustomerModal open={openCustomerModal} setOpen={setOpenCustomerModal} handleCustomerCreated={handleCustomerCreated} />
        </>
    );
};

export default CreateJobModal;
