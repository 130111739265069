import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Dashboard from './Components/StaffPortal/Dashboard';
import BookingForm from './Components/CustomerPortal/BookingForm';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<BookingForm />} />
          <Route path="/staff" element={<Dashboard />} />
        </Routes>
      </Router>

    </div>
  );
}

export default App;
