import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { Select, MenuItem, FormControl, InputLabel, Typography } from '@mui/material';
import LoadingComponent from '../Loading';

interface TimeslotSelectorProps {
    onTimeslotSelect: (timeslot: Date) => void;
    ref?: React.Ref<{ reset: () => void }>;
}

const TimeslotSelector = forwardRef<{ reset: () => void }, TimeslotSelectorProps>(
    ({ onTimeslotSelect }, ref) => {
        const [loading, setLoading] = useState(true);
        const [timeslots, setTimeslots] = useState<Date[]>([]);
        const [selectedDay, setSelectedDay] = useState<string>('');
        const [filteredTimeslots, setFilteredTimeslots] = useState<Date[]>([]);
        const [selectedTimeslot, setSelectedTimeslot] = useState<Date | null>(null);

        useEffect(() => {
            const fetchTimeslots = async () => {
                try {
                    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/CustomerBooking/timeslots`, {
                        method: 'GET',
                    });
                    if (!response.ok) {
                        throw new Error('Failed to fetch timeslots');
                    }
                    const data: string[] = await response.json(); // Assuming the API returns an array of ISO date strings
                    setTimeslots(data.map((slot) => new Date(slot))); // Convert strings to Date objects
                    setLoading(false);
                } catch (error) {
                    console.error(error);
                }
            };

            fetchTimeslots();
        }, []);

        useEffect(() => {
            if (selectedDay) {
                const filtered = timeslots.filter(
                    (slot) => new Date(slot).toDateString() === new Date(selectedDay).toDateString()
                );
                setFilteredTimeslots(filtered);
            }
        }, [selectedDay, timeslots]);

        const handleDayChange = (event: any) => {
            const day = event.target.value as string;
            setSelectedDay(day);
            setSelectedTimeslot(null); // Reset the selected timeslot
        };

        const handleTimeslotChange = (event: any) => {
            const slot = new Date(event.target.value as string);
            setSelectedTimeslot(slot);
            onTimeslotSelect(slot); // Pass the selected timeslot to the parent
        };

        // Allow parent component to reset the state
        useImperativeHandle(ref, () => ({
            reset: () => {
                var remainingTimeslots = timeslots.filter(a => a?.toISOString() != selectedTimeslot?.toISOString())
                setTimeslots(remainingTimeslots)
                setSelectedDay('');
                setSelectedTimeslot(null);
                setFilteredTimeslots([]);
            },
        }));

        // Get unique days with available slots
        const daysWithSlots = timeslots.reduce<{ [key: string]: number }>((acc, slot) => {
            const dayString = slot.toDateString();
            acc[dayString] = (acc[dayString] || 0) + 1;
            return acc;
        }, {});

        const uniqueDaysWithCounts = Object.entries(daysWithSlots)
            .filter(([_, count]) => count > 0) // Exclude days with no available slots
            .map(([day, count]) => ({ day, count }));

        if (loading) {
            return <LoadingComponent />
        }

        if (timeslots.length == 0) {
            return <Typography variant="h6" noWrap>
                No timeslots are available
            </Typography>
        }

        return (
            <div>
                {/* Day Selector */}
                <FormControl fullWidth margin="normal">
                    <InputLabel id="day-select-label">Day</InputLabel>
                    <Select
                        labelId="day-select-label"
                        value={selectedDay}
                        onChange={handleDayChange}
                        label="Day"
                    >
                        {uniqueDaysWithCounts.map(({ day, count }) => (
                            <MenuItem key={day} value={day}>
                                {`${day} (${count} slots available)`}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {/* Timeslot Selector */}
                {selectedDay && (
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="timeslot-select-label">Timeslot</InputLabel>
                        <Select
                            labelId="timeslot-select-label"
                            label="Timeslot"
                            value={selectedTimeslot?.toISOString() || ''}
                            onChange={handleTimeslotChange}
                        >
                            {filteredTimeslots.map((slot) => (
                                <MenuItem key={slot.toISOString()} value={slot.toISOString()}>
                                    {slot.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            </div>
        );
    }
);

export default TimeslotSelector;
