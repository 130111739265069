import { BaseUser } from "../BaseUser";
import { StaffRoleType } from "./StaffRoleType";

export class Staff implements BaseUser {
    public tenantId!: string;
    public id!: string;
    public roleType!: StaffRoleType;
    public firstName!: string;
    public lastName!: string;
    public phone!: string;
    public email!: string;
}