import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import axios from 'axios';
import { Job } from '../../DatabaseEntities/Job/Job';
import { CustomerBookingType } from '../../DatabaseEntities/CustomerBooking/CustomerBookingType';
import LoadingComponent from '../Loading';
import ViewJobModal from './ViewJobModal';

const localizer = momentLocalizer(moment)

interface CalendarProps {
    ref?: React.Ref<{ jobCreated: (job: Job) => void }>;
}

const MyCalendar = forwardRef<{ jobCreated: (job: Job) => void }, CalendarProps>(
    ({ }, ref) => {
        const [jobs, setJobs] = useState<Job[] | null>(null);
        const [loading, setLoading] = useState(true);
        const [error, setError] = useState(null);
        const [openViewJobModal, setOpenViewJobModal] = useState(false);
        const [clickedJobId, setClickedJobId] = useState<string | null>(null);

        useImperativeHandle(ref, () => ({
            jobCreated: (job: Job) => {
                jobs?.push(job)
                setJobs(jobs)
            },
        }));

        useEffect(() => {
            var currentDate = new Date();
            var nextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1);

            const startOfMonth = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-1`;
            const endOfMonth = `${nextMonth.getFullYear()}-${nextMonth.getMonth() + 1}-1`;
            const endpoint = `${process.env.REACT_APP_SERVER_URL}/api/Job?startDate=${startOfMonth}&endDate=${endOfMonth}`;

            axios.get(endpoint)
                .then(response => {
                    setJobs(response.data);
                    setLoading(false);
                })
                .catch(error => {
                    setError(error);
                    setLoading(false);
                });
        }, []);

        const handleEventClick = (event: any) => {
            setClickedJobId(event.jobId);
            setOpenViewJobModal(true);
        };

        if (loading) {
            return <LoadingComponent />
        }

        return <div style={{ height: "800px" }}>
            <>
                <Calendar
                    dayLayoutAlgorithm={'no-overlap'}
                    localizer={localizer}
                    events={jobs?.map(job => {
                        const startDate = new Date(job.timeslotUTC);
                        const endDate = new Date(job.timeslotUTC);
                        endDate.setHours(endDate.getHours() + job.estimatedHours);
                        return {
                            title: CustomerBookingType[job.bookingType],
                            start: startDate,
                            end: endDate,
                            jobId: job.id
                        };
                    })}
                    onSelectEvent={handleEventClick} // Handle event click here
                    startAccessor="start"
                    endAccessor="end"
                    defaultView='week'
                    scrollToTime={new Date(0, 0, 0, 23)}
                />
                {clickedJobId && <ViewJobModal jobId={clickedJobId} open={openViewJobModal} setOpen={setOpenViewJobModal} setClickedJobId={setClickedJobId} />}
            </>
        </div>
    }
);

export default MyCalendar;