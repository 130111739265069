import React, { useState, useRef } from 'react';
import { TextField, Button, MenuItem, Select, FormControl, InputLabel, FormHelperText, Box, AppBar, CssBaseline, Toolbar, Typography } from '@mui/material';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { BookingFormModel } from '../../Models/BookingFormModel';
import { CustomerBookingType } from '../../DatabaseEntities/CustomerBooking/CustomerBookingType';
import 'dayjs/locale/en-gb';
import TimeslotSelector from './TimeslotSelector';

const BookingForm = () => {
  const [formData, setFormData] = useState<BookingFormModel>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    reasonForBooking: null,
    timeslotUTC: null
  });

  const [error, setError] = useState('');
  const timeslotSelectorRef = useRef<{ reset: () => void }>(null);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    // Validation
    if (!formData.firstName || !formData.lastName || !formData.email || !formData.phone || !formData.reasonForBooking || !formData.timeslotUTC) {
      setError('Please fill out all fields.');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/CustomerBooking`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });


      if (!response.ok) {

        console.log(response.status);
        console.log(response.status === 409);
        if (response.status === 409) {
          timeslotSelectorRef.current?.reset();
          throw new Error('Apologies- this booking has just been taken, please try another timeslot.');
        }

        throw new Error('Failed to submit booking.');
      }

      alert('Booking submitted successfully. You will be emailed with confirmation shortly.');

      // Reset the form
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        reasonForBooking: null,
        timeslotUTC: null,
      });

      // Reset the TimeslotSelector
      timeslotSelectorRef.current?.reset();

      setError('');
    } catch (err: any) {
      setError(err.message);
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>


      <CssBaseline />

      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <Typography variant="h6" noWrap>
            Some Garage Company
          </Typography>
        </Toolbar>
      </AppBar>



      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          marginTop: "100px"
        }}
      >
        <Typography variant="h6" noWrap>
          Use this form to book an appointment at the garage
        </Typography>
        <form onSubmit={handleSubmit} style={{ maxWidth: 400, margin: '0 auto' }}>
          <TextField
            label="First Name"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Last Name"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
          <FormControl fullWidth margin="normal" required>
            <InputLabel>Reason for Booking</InputLabel>
            <Select
              name="reasonForBooking"
              value={formData.reasonForBooking}
              onChange={handleChange}
              label="Reason for Booking" // Ensures the label floats correctly
            >
              {Object.keys(CustomerBookingType)
                .filter(key => isNaN(Number(key))) // Filters out the numeric values
                .map(key => (
                  <MenuItem key={key} value={CustomerBookingType[key as keyof typeof CustomerBookingType]}>
                    {key}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <TimeslotSelector ref={timeslotSelectorRef} onTimeslotSelect={(timeslotUTC: Date) => setFormData((prev) => ({ ...prev, timeslotUTC }))} />
          <FormHelperText>{error}</FormHelperText>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Submit
          </Button>
        </form>
      </Box>
    </Box >
  );
};

export default BookingForm;
