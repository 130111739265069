import React, { useState } from 'react';
import { Box, Button, Modal, TextField } from '@mui/material';
import { Staff } from '../../DatabaseEntities/User/Staff/Staff';

interface CreateStaffModalProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    handleStaffCreated: (staff: Staff) => void;
}

const CreateStaffModal = ({ open, setOpen, handleStaffCreated }: CreateStaffModalProps) => {

    const [staff, setStaff] = useState(new Staff());

    // Handle input change for job form
    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setStaff((prevStaff) => ({
            ...prevStaff,
            [name as string]: value,
        }));
    };

    const handleSubmit = () => {
        if (!staff.firstName || !staff.lastName || !staff.email || !staff.phone) {
            return;
        }

        const saveStaff = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/Staff`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(staff),
                });
                if (!response.ok) {
                    throw new Error('Failed to save customer');
                }
                const data: Staff = await response.json();
                console.log(data);
                handleStaffCreated(data);
                setStaff(new Staff());
                setOpen(false);
            } catch (error) {
                console.error(error);
            }
        };

        saveStaff();
    };

    return (
        <Modal open={open} onClose={() => setOpen(false)}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'white',
                padding: 4,
                width: 400,
                boxShadow: 24,
                height: 800,
                display: 'flex', // Make the container a flexbox
                flexDirection: 'column', // Arrange children in a column
                justifyContent: 'space-between', // Ensure space between elements
            }}>
                <h2>Create Customer</h2>

                <Box sx={{ flexGrow: 1 }}> {/* Container for inputs */}
                    <TextField
                        label="First name"
                        type="string"
                        name="firstName"
                        value={staff.firstName}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required
                    />

                    <TextField
                        label="Last name"
                        type="string"
                        name="lastName"
                        value={staff.lastName}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required
                    />

                    <TextField
                        label="Email"
                        name="email"
                        type="email"
                        value={staff.email}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required
                    />

                    <TextField
                        label="Phone"
                        name="phone"
                        value={staff.phone}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required
                    />
                </Box>

                <Button onClick={handleSubmit} fullWidth variant="contained" sx={{ marginTop: 2 }}>Submit</Button>
            </Box>
        </Modal>
    );
};

export default CreateStaffModal;
