import React, { useRef, useState } from 'react';
import { Box, CssBaseline, AppBar, Toolbar, Drawer, Typography, Button } from '@mui/material';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import MyCalendar from './MyCalendar';
import CreateJobModal from './CreateJobModal';
import { Job } from '../../DatabaseEntities/Job/Job';
import CreateCustomerModal from './CreateCustomerModal';
import { Customer } from '../../DatabaseEntities/User/Customer';
import CreateStaffModal from './CreateStaffModal';
import { Staff } from '../../DatabaseEntities/User/Staff/Staff';

const drawerWidth = 240;

const Dashboard = () => {
    const [openJobModal, setOpenJobModal] = useState(false);
    const [openCustomerModal, setOpenCustomerModal] = useState(false);
    const [openStaffModal, setOpenStaffModal] = useState(false);
    const calendarRef = useRef<{ jobCreated: (job: Job) => void }>(null);

    const handleJobCreated = (job: Job) => {
        calendarRef?.current?.jobCreated(job);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <Typography variant="h6" noWrap>
                        Some Garage Company
                    </Typography>
                </Toolbar>
            </AppBar>

            <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                }}
            >
                <Toolbar />
                <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
                    <Button onClick={() => setOpenJobModal(true)}>Create Job</Button>
                    <Button onClick={() => setOpenCustomerModal(true)}>Create Customer</Button>
                    <Button onClick={() => setOpenStaffModal(true)}>Create Staff member</Button>
                </Box>
            </Drawer>

            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                }}
            >
                <Toolbar />
                <MyCalendar ref={calendarRef} />
            </Box>

            <CreateJobModal open={openJobModal} setOpen={setOpenJobModal} handleJobCreated={handleJobCreated} />
            <CreateCustomerModal open={openCustomerModal} setOpen={setOpenCustomerModal} handleCustomerCreated={(customer: Customer) => { }} />
            <CreateStaffModal open={openStaffModal} setOpen={setOpenStaffModal} handleStaffCreated={(staff: Staff) => { }} />
        </Box>
    );
};

export default Dashboard;
