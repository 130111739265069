import React, { useEffect, useState } from 'react';
import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import { JobModel } from '../../Models/JobModel';
import LoadingComponent from '../Loading';
import { CustomerBookingType } from '../../DatabaseEntities/CustomerBooking/CustomerBookingType';

interface ViewJobModalProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setClickedJobId: React.Dispatch<React.SetStateAction<string | null>>;
    jobId: string;
}

const ViewJobModal = ({ open, setOpen, jobId, setClickedJobId }: ViewJobModalProps) => {
    const [jobModel, setJobModel] = useState<JobModel | null>(null);

    useEffect(() => {
        const getJobModel = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/Job/${jobId}`, {
                    method: 'GET',
                });
                if (!response.ok) {
                    throw new Error('Failed to get job model');
                }
                const data: JobModel = await response.json();
                setJobModel(data);
            } catch (error) {
                console.error(error);
            }
        };
        getJobModel();
    }, [jobId])


    if (!jobModel) {
        return <LoadingComponent />;
    }

    function formatDate(date: Date): string {
        const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const dayName = days[date.getDay()]; // Get full day name, e.g., "Friday"

        const day = date.getDate(); // Get the numeric day, e.g., 27
        const ordinalSuffix = (n: number) => {
            if (n > 3 && n < 21) return "th"; // Handle 11th–19th
            switch (n % 10) {
                case 1: return "st";
                case 2: return "nd";
                case 3: return "rd";
                default: return "th";
            }
        };

        const suffix = ordinalSuffix(day);
        const year = date.getFullYear(); // Get the full year, e.g., 2024

        const hours = String(date.getHours()).padStart(2, "0"); // Ensure 2-digit hour
        const minutes = String(date.getMinutes()).padStart(2, "0"); // Ensure 2-digit minute

        return `${dayName} ${day}${suffix} ${year}, ${hours}:${minutes}`;
    }

    return (
        <Modal open={open} onClose={() => {
            setJobModel(null)
            setClickedJobId(null)
            setOpen(false);
        }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'white',
                    padding: 4,
                    width: 400,
                    boxShadow: 24,
                    height: 800,
                    display: 'flex',
                    flexDirection: 'column', // Arrange content vertically
                }}
            >
                <h2>Job details</h2>

                <Box sx={{ flexGrow: 1 }}>
                    <TextField
                        label="Customer"
                        type="string"
                        name="cusotomer"
                        value={`${jobModel.customer.firstName} ${jobModel.customer.lastName}`}
                        fullWidth
                        margin="normal"
                        required
                        disabled
                    />
                    <TextField
                        label="Booking type"
                        type="string"
                        name="bookingType"
                        value={CustomerBookingType[jobModel.job.bookingType]}
                        fullWidth
                        margin="normal"
                        required
                        disabled
                    />
                    <TextField
                        label="Timeslot"
                        type="string"
                        name="timeslot"
                        value={formatDate(new Date(jobModel.job.timeslotUTC))}
                        fullWidth
                        margin="normal"
                        required
                        disabled
                    />

                    <br />
                    <br />
                    <Typography>Assigned staff</Typography>
                    {jobModel.assignedStaff.map((staffMember, index) => {
                        const staffName = `${staffMember.firstName} ${staffMember.lastName}`;

                        return (
                            <TextField
                                key={index}
                                label={staffName}
                                type="string"
                                name={staffName}
                                value={staffName}
                                fullWidth
                                margin="normal"
                                required
                                disabled
                            />
                        );
                    })}
                </Box>

                <Button
                    onClick={() => setOpen(false)}
                    fullWidth
                    variant="contained"
                    sx={{ marginTop: 'auto' }} // Push the button to the bottom
                >
                    Close
                </Button>
            </Box>
        </Modal>
    );

};

export default ViewJobModal;
