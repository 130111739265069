import React, { useState } from 'react';
import { Box, Button, Modal, TextField } from '@mui/material';
import { Customer } from '../../DatabaseEntities/User/Customer';

interface CreateCustomerModalProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    handleCustomerCreated: (customer: Customer) => void;
}

const CreateCustomerModal = ({ open, setOpen, handleCustomerCreated }: CreateCustomerModalProps) => {

    const [customer, setCustomer] = useState(new Customer());

    // Handle input change for job form
    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setCustomer((prevCustomer) => ({
            ...prevCustomer,
            [name as string]: value,
        }));
    };

    const handleSubmit = () => {
        if (!customer.firstName || !customer.lastName || !customer.email || !customer.phone) {
            return;
        }

        const saveCustomer = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/Customer`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(customer),
                });
                if (!response.ok) {
                    throw new Error('Failed to save customer');
                }
                const data: Customer = await response.json();
                console.log(data);
                handleCustomerCreated(data);
                setCustomer(new Customer());
                setOpen(false);
            } catch (error) {
                console.error(error);
            }
        };

        saveCustomer();
    };

    return (
        <Modal open={open} onClose={() => setOpen(false)}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'white',
                padding: 4,
                width: 400,
                boxShadow: 24,
                height: 800,
                display: 'flex', // Make the container a flexbox
                flexDirection: 'column', // Arrange children in a column
                justifyContent: 'space-between', // Ensure space between elements
            }}>
                <h2>Create Customer</h2>

                <Box sx={{ flexGrow: 1 }}> {/* Container for inputs */}
                    <TextField
                        label="First name"
                        type="string"
                        name="firstName"
                        value={customer.firstName}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required
                    />

                    <TextField
                        label="Last name"
                        type="string"
                        name="lastName"
                        value={customer.lastName}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required
                    />

                    <TextField
                        label="Email"
                        name="email"
                        type="email"
                        value={customer.email}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required
                    />

                    <TextField
                        label="Phone"
                        name="phone"
                        value={customer.phone}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required
                    />
                </Box>

                <Button onClick={handleSubmit} fullWidth variant="contained" sx={{ marginTop: 2 }}>Close</Button>
            </Box>
        </Modal>
    );
};

export default CreateCustomerModal;
